// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Gelasio:700|Open+Sans:400,600&display=swap');

// FONTS
$gelasio: 'Gelasio', serif;
$open_sans: 'Open Sans', sans-serif;

// COLORS
$purple: #B998FF;
$yellow: #FFD853;
$blue: #559AFF;
$red: #FF6060;
$green: #47DD85;
$orange: #FFA05F;
$teal: #2AEE06;
$blue_purple: #7093FF;
$black: #02241F;
$black_bg: #1a1919;
$white: #FFFCF0;

// Responsiveness
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

/* MIXINS - Mobile-first Approach */
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

html,
body {
	max-width: 720px;
	color: $black;
	background-color: $white;
	padding: 10px 20px 0px;
	margin: 0px auto;
	box-sizing: border-box;
	outline: 0;
	height: 100%;
	font-family: $open_sans;
	font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $gelasio;
}

ul, li {
	padding: 0px;
	margin: 0px;
}

a {
	text-decoration: none;
	color: $black;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;

	&:hover {
		color: $red;
		border-radius: 4px;
		border-bottom: 4px solid $red;
	}
}

.container {
	margin: 30px auto 60px;
}

.title {
	color: $white;
	font-size: 1.25rem;
	display: inline-block;
	border-radius: 5px;
	background-color: $black;
	padding: 6px 12px;
	margin: 4px 0px;
}

// Navbar
.navbar {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	padding: 14px 0px;
	border-bottom: 1px solid $red;

	&_left {
		h1 {
			margin: 0px;
		}
		a {
			font-size: 26px;
			font-family: $gelasio;

			@include sm {
				font-size: 36px;
			}

			&:hover {
				border-radius: none;
				border-bottom: none;
			}
		}
	}
	&_right {
		a {
			font-size: 12px;
			margin-left: 8px;
			margin-right: 8px;
			font-weight: 600;
			line-height: 0.7;
			letter-spacing: .1em;
			opacity: 1;
			text-transform: uppercase;

			@include sm {
				font-size: 14px;
			}
		}
	}

}

// Home
.home {
	margin-bottom: 48px;
	padding-bottom: 4rem;

	div:first-of-type {
		margin-bottom: 20px;
	}

	a {
		color: $red;
	}

	p {
		span {
			margin-right: 8px;
		}
	}

	.short {
		p {
			strong {
				border-bottom: 3px solid $red;
			}
		}
	}

	&_photos {
		h2 {
			span {
				color: $blue;
				font-size: 12px;
				font-weight: 400;
			}
		}
		img {
			width: 100px;
			height: 100px;
			border: 1px solid $black;
			border-radius: 8px;
			margin-right: 5px;
		}
	}
}

// Now
.now, .gear {
	padding-top: 16px;
	padding-bottom: 48px;

	&_section {
		h1 {
			font-size: 24px;
		}

		h2 {
			margin-bottom: 16px;
		}

		&:nth-of-type(2) {
			h3 {
				display: inline-block;
				border-bottom: 3px solid $red;
				margin: 0;
			}
		}

		.reads {
			display: flex;
			justify-content: space-between;
			align-items: baseline;

			h3 {
				font-size: 18px;

				@include sm {
					font-size: 20px;
				}
			}
			p {
				font-size: 10px;
				font-weight: 600;
				text-transform: uppercase;

				@include sm {
					font-size: 11px;
				}
			}
		}
		a {
			color: $red;

			&:hover {
				border-radius: 4px;
				border-bottom: 4px solid $red;
			}
		}
	}
}

// Readings
.reads {
	&_book {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: flex-start;
		padding: 1rem 0;
		border-top: 1px solid $blue;
		font-size: 0.8rem;
	}

	&_cover {
		flex: 0.3;
		align-self: auto;

		@include sm {
			flex: 0.2;
		}

		img {
			display: inline;
			margin: 0;
			max-width: 80px;
		}
	}

	&_metadata {
		flex: 0.7;
		align-self: auto;

		@include sm {
			flex: 0.8;
		}

		h3 {
			margin-top: 0;
			a {
				color: $red;
			}
		}
		p {
			margin: 0.5rem 0;
		}
	}
}

